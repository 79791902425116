<template>
  <b-form class="form" v-on:submit.prevent="onSubmit">
    <h4 class="font-weight-bolder mb-5"></h4>

    <!-- begin: days  -->
    <b-form-group
      id="days"
      label-cols-lg="2"
      label-class="font-weight-bolder"
      :label="$t('ENTITLEMENT.DAYS')"
    >
      <b-form-input
        id="days"
        v-model="$v.form.days.$model"
        :state="validateState('days')"
        type="number"
        max="365"
      >
      </b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.days.required"
        >{{ $t("ALERT.ALERT") }}
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.days.numeric"
        >Numbers only!
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.days.maxValue"
        >Max allowed days : {{ $v.form.days.$params.maxValue.max }}
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- end: days  -->

    <!-- begin: balance  -->
    <b-form-group
      id="days"
      label-cols-lg="2"
      label-class="font-weight-bolder"
      label="Balance"
    >
      <b-form-input
        id="days"
        class="form-control-solid"
        v-model="balance"
        readonly
        disabled
      >
      </b-form-input>
    </b-form-group>
    <!-- end: balance  -->

    <div class="d-flex justify-content-end border-top pt-3">
      <b-button
        variant="primary"
        ref="employee-general-button"
        class="font-size-h6"
        type="submit"
      >
        {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { maxValue, numeric, required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import calculateBalance from "@/modules/company/services/leave/entitlement/leave_balance_calculator";

export default {
  name: "LeavePageEntitlementForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: "Entitlement Form",
    },
  },
  data() {
    return {
      form: {
        days: "",
      },
      balance: "",
      holdingBalance: 0,
      previousDays: null,
      holdingDays: 0,
      isBusy: true,
    };
  },
  validations: {
    form: {
      days: {
        required,
        numeric,
        maxValue: maxValue(365),
      },
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      console.log("Submit");
      console.log("Employee Entitlement Form:", this.form);

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const form = {
        id: this.assigned.id,
        employee_id: this.assigned.employee_id,
        days: this.form.days,
        balance: this.balance,
      };

      this.isBusy = true;
      this.$store
        .dispatch("submitEntitlementForm", form)
        .then(() => {
          this.$store.dispatch("assignEntitlementFormIsSaved", true);
        })
        .catch((reason) => {
          console.error(reason);
          this.$bvModal.msgBoxOk("Failed to update Leave", {
            centered: true,
            okVariant: "danger",
          });
        })
        .finally(() => (this.isBusy = false));
    },
  },

  computed: {
    ...mapGetters({
      assigned: "getAssignEmployeeToLeaveEntitlement",
      fetchedForm: "getEntitlementForm",
    }),
  },

  watch: {
    form: {
      handler: function (newForm, oldForm) {
        console.log(newForm, oldForm);

        console.log("Current Balance", this.balance);
        this.balance = calculateBalance(
          parseInt(newForm.days),
          this.holdingDays,
          this.balance,
          this.holdingBalance
        );
      },
      deep: true,
    },

    fetchedForm(newForm) {
      if (newForm !== null) {
        this.form.days = parseInt(newForm.entitlement.unit_entitled);
        this.balance = parseInt(newForm.entitlement.balance);
        this.holdingBalance = parseInt(newForm.entitlement.balance);
        this.holdingDays = parseInt(newForm.entitlement.unit_entitled);
      }
    },
  },
};
</script>

<style scoped></style>
