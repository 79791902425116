<template>
  <b-modal
    id="leave-page-entitlement-modal"
    :title="$t('ENTITLEMENT.DETAILS')"
    title-class="h4 font-weight-bold"
    lazy
    size="lg"
    hide-footer
  >
    <div class="d-flex flex-column p-5">
      <CompanyLeaveDetails
        class="mb-2"
        :leave="leave"
        :is-busy="fetchBusy"
      ></CompanyLeaveDetails>
      <LeavePageEntitlementEditForm></LeavePageEntitlementEditForm>
    </div>
  </b-modal>
</template>

<script>
import LeavePageEntitlementEditForm from "@/modules/company/components/leave/entitlement/employee/LeaveEmployeeEntitlementPageForm";
import CompanyLeaveDetails from "@/modules/company/components/shared/CompanyLeaveDetails";
import { mapGetters } from "vuex";

export default {
  name: "LeavePageEntitlementModal",
  components: { CompanyLeaveDetails, LeavePageEntitlementEditForm },
  data() {
    return {
      currentTab: 0,
      busy: false,
    };
  },
  computed: {
    ...mapGetters({
      leave: "getSingleCompanyLeave",
      item: "getAssignEmployeeToLeaveEntitlement",
      fetchBusy: "getSingleCompanyLeaveBusy",
    }),
    getLeave() {
      return {
        name: "Haha",
      };
    },
  },
};
</script>

<style scoped></style>
