<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <div class="flex-row">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("LEAVE.ENTITLEMENT") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <b-button
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-on:click="refresh"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </b-button>
      </div>
    </div>
    <div>
      <b-alert
        variant="info"
        show="5"
        fade
        dismissible
        v-if="formData.length > 0"
      >
        {{ formData }}
      </b-alert>
    </div>

    <LeavePageEntitlementList :reload="reload"></LeavePageEntitlementList>
  </b-card>
</template>

<script>
import LeavePageEntitlementList from "@/modules/company/components/leave/entitlement/LeaveEntitlementPageTable";

export default {
  name: "LeavePageEntitlement",
  components: { LeavePageEntitlementList },
  mounted() {},
  data() {
    return {
      formData: "",
      reload: false,
    };
  },
  methods: {
    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
